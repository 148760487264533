<template>
	<!-- 报警系统 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[6]}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<div class="alarm_box">
						<div class="head">
							<div class="g_btn btn" @click="addClick">{{$t('alarmSystem.addAlarm')}}</div>
							<div class="g_btn btn" @click="bindTelegram"><i class="iconfont icon-telegram"></i>{{$t('alarmSystem.telegram')}}</div>
						</div>
						<template v-if="alarmList.length">
							<div class="list">
								<div class="item" v-for="(item,index) in alarmList" :key="index">
									<div class="li">
										<div class="title">{{$t('common.exchange')}}</div>
										<div class="text">{{item.name}}</div>
									</div>
									<div class="li">
										<div class="title">{{$t('common.pair')}}</div>
										<div class="text">{{item.currency.replace('_',' / ')}}</div>
									</div>
									<div class="li">
										<div class="title">{{$t('alarmSystem.parameter')}}</div>
										<div class="text">
											<p>{{paramFotmat(item)}}</p>
										</div>
									</div>
									<div class="li">
										<div class="title">{{$t('alarmSystem.currentState')}}</div>
										<div class="text">
											<span v-if="item.state == '1'" class="green">{{$t('alarmSystem.operation')}}</span>
											<span v-else class="red">{{$t('alarmSystem.stopped')}}</span>
										</div>
									</div>
									<div class="li">
										<div class="title">{{$t('common.operation')}}</div>
										<div class="text">
											<span class="btn green" @click="handleEdit(item)">{{$t('common.edit')}}</span>
											<span v-if="item.state == '1'" class="btn red" @click="handleStop(item)">{{$t('common.stop')}}</span>
											<span v-else class="btn green" @click="handleStart(item)">{{$t('common.run')}}</span>
											<span class="btn red" @click="handleDelete(item)">{{$t('common.del')}}</span>
										</div>
									</div>
								</div>
							</div>
						</template>
						<div class="list emptyList" v-else>
							<el-empty :image="emptyImg" :image-size="120" :description="$t('alarmSystem.noData')" />
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="isMobile==1">
			<!-- 移动端判定 -->
			<div class="content_phone">
				<!-- 未绑定电报 -->
				<div v-if="!telegram" class="no_teleram">
					<div class="icon">
						<!-- 电报Icon -->
						<img src="@/assets/images/telegram.png" alt="telegram">
					</div>
					<div class="text">{{$t('tip.noTeleram')}}</div>
					<div class="g_btn btn" @click="bindTelegram">{{$t('alarmSystem.telegram')}}</div>
				</div>
				<div v-else class="alarm_box">
					<template v-if="alarmList.length">
						<div class="list">
							<div class="item" v-for="(item,index) in alarmList" :key="index">
								<div class="li">
									<div class="title">{{$t('common.exchange')}}</div>
									<div class="text">{{item.name}}</div>
								</div>
								<div class="li">
									<div class="title">{{$t('common.pair')}}</div>
									<div class="text">{{item.currency.replace('_',' / ')}}</div>
								</div>
								<div class="li">
									<div class="title">{{$t('alarmSystem.parameter')}}</div>
									<div class="text">
										<p>{{paramFotmat(item)}}</p>
									</div>
								</div>
								<div class="li">
									<div class="title">{{$t('alarmSystem.currentState')}}</div>
									<div class="text">
										<span v-if="item.state == '1'" class="green">{{$t('alarmSystem.operation')}}</span>
										<span v-else class="red">{{$t('alarmSystem.stopped')}}</span>
									</div>
								</div>
								<div class="btn_box">
									<span class="g_btn btn green" @click="handleEdit(item)">{{$t('common.edit')}}</span>
									<span v-if="item.state == '1'" class="g_btn btn red" @click="handleStop(item)">{{$t('common.stop')}}</span>
									<span v-else class="g_btn btn green" @click="handleStart(item)">{{$t('common.run')}}</span>
									<span class="g_btn btn red" @click="handleDelete(item)">{{$t('common.del')}}</span>
								</div>
							</div>
						</div>
					</template>
					<div class="list emptyList" v-else>
						<el-empty :image="emptyImg" :image-size="120" :description="$t('alarmSystem.noData')">
							<div class="g_btn btn" @click="addClick">{{$t('alarmSystem.addAlarm')}}</div>
						</el-empty>
					</div>
				</div>
			</div>
		</template>
		
		<!-- 一级添加弹窗 -->
		<el-dialog :title="dialogTitle" :visible.sync="showSelectBox" :before-close="beforeClose">
			<div class="add_form">
				<div class="form">
					<div class="item">
						<div class="title title1">{{$t('common.exchange')}}</div>
						<div class="select" @click="popExchange = true">
							<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
					<div class="item" v-if="web!='opensea'">
						<div class="title title1">{{$t('common.pair')}}</div>
						<div class="doubletext">
							<input v-model="form.yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
							<i class="split"></i>
							<input v-model="form.yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
						</div>
					</div>
					<div class="item" v-else>
						<div class="title title1">{{$t('common.opensea')}}</div>
						<div class="opensea">
							<el-input v-model="form.yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
						</div>
					</div>
				</div>
				<div class="foot">
					<div class="btn" @click="handleCancel1">{{$t('common.back')}}</div>
					<div class="btn submit" @click="toNext">{{$t('common.next')}}</div>
				</div>
			</div>
		</el-dialog>
		<!-- 二级弹窗 -->
		<alarmDialog 
			ref="alarmDialog" 
			@handleBack="handleBack"
			@getList="getList"
		></alarmDialog>
		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	import { Loading } from 'element-ui';
	import ExchangeDialog from '@/components/exchange.vue';
	import alarmDialog from "@/components/alarm/alarmDialog.vue";

	import { mapGetters } from 'vuex';
	import bus from "../bus.js";
    import { getTradingPairInfo } from '@/utils/methApi/tradingPairDefault'
	// api
	import { 
		getAlarmList,
		setAlarm,
		delAlarm,
	 } from '@/api/alarm';
	export default ({
		name: 'alarm',
		components: {
			ExchangeDialog,
   	 		alarmDialog,
		},
		data() {
			return {
				showSelectBox: false,
				emptyImg: require('@/assets/images/nosignal.png'),
				
				// 报警弹窗数据 1级弹窗传给2级弹窗
				type:'add',//add edit
				state:'0',
				
				// 此处数据仅用于测试,请自行设置数据名称
				alarmList: [],
				form: {},
				paramArr:[],

				// 交易所选择
				popExchange: false,
				web: null,
			}
		},
		computed:{
			...mapGetters(['account','isMobile','telegram']),
			dialogTitle:function(){
				if(this.type=='add'){
					// return '新建触发报警器'
					return this.$t('alarmSystem.addTrigger')
				}else{
					// return '编辑触发报警器'
					return this.$t('alarmSystem.editTrigger')
				}
			}
		},
		created() {
			this.getList();
			bus.$on('add',()=>{
				this.addClick()
			})
		},
		methods: {
			// 绑定电报
			bindTelegram(){
				// window.open('https://t.me/lightning_mm_bot')
				window.location.href='https://t.me/lightning_mm_bot'
			},
			// 参数设置格式化
			paramFotmat(item){
				var str=item.param?
					(item.param.split(' ')[0]?this.$t('alarmSystem.priceThan')+item.param.split(' ')[0]+', ':'')+
					(item.param.split(' ')[1]?this.$t('alarmSystem.priceBelow')+item.param.split(' ')[1]+', ':'')+
					(item.param.split(' ')[2]?item.currency.split('_')[0]+
						this.$t('alarmSystem.less')+item.param.split(' ')[2]+', ':'')+
					(item.param.split(' ')[3]?item.currency.split('_')[1]+
						this.$t('alarmSystem.less')+item.param.split(' ')[3]+', ':'')+
					(item.param.split(' ')[5]?this.$t('alarmSystem.mutation')+item.param.split(' ')[5]+'%, ':'')+
					(item.param.split(' ')[4]==1?`${this.$t('alarmSystem.conditions')}  `:'')+
					(item.param.split(' ')[6]==1?`${this.$t('alarmSystem.colseRobot')}  `:'')
					:''
					
				return str.substr(0,str.length-2)							
			},
			getList(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				getAlarmList({
					account:this.account
				}).then(res=>{
					this.alarmList=res;
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
			// 添加
			addClick(){
				this.type='add';
				this.showSelectBox = true;
				this.form={
					exchange: getTradingPairInfo('exchange'),
					yenUnit1: getTradingPairInfo('yenUnit1'),
					yenUnit2: getTradingPairInfo('yenUnit2'),
				}
				this.web = getTradingPairInfo('web');
			},
			// 编辑
			handleEdit(item) {
				this.type='edit';
				// 数据
				this.web = item.web;
				var currency=item.currency.split('_')
				this.form = {
					exchange:item.name,
					yenUnit1:currency[0],
					yenUnit2:currency[1],
				}
				this.paramArr=item.param?item.param.split(' '):'';

				this.state=item.state

				this.showSelectBox = true;
			},
			beforeClose(done){
				this.form = {};
				this.paramArr=[];
				this.web= null;
				done()
			},
			// 一级弹窗
			handleCancel1(){
				// 返回初始化
				this.form = {};
				this.paramArr=[];
				this.web= null;
				this.showSelectBox=false;
			},
			// 下一步-打开二级弹窗
			toNext(){
				if(!this.web){
					this.$message({
						message: this.$t('tip.selectExchange')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(this.web!='opensea'){
					if(!this.form.yenUnit1||!this.form.yenUnit2){
						this.$message({
							message: this.$t('tip.enterPair')+'！',
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						this.showSelectBox=false;
						this.$refs.alarmDialog?.initAlarm({
							web:this.web,
							yenUnit1:this.form.yenUnit1,
							yenUnit2:this.form.yenUnit2,
							paramArr:this.paramArr,
							state:this.state,
							type:this.type
						});
					}
				}else{
					// opensea
					if(!this.form.yenUnit1){
						this.$message({
							message: this.$t('tip.enterCollect')+'！',
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						this.showSelectBox=false;
						this.$refs.alarmDialog?.initAlarm({
							web:this.web,
							yenUnit1:this.form.yenUnit1,
							yenUnit2:this.form.yenUnit2,
							paramArr:this.paramArr,
							state:this.state,
							type:this.type
						});
					}
				}
			},
			// 返回上一步-打开一级弹窗
			handleBack() {
				this.showSelectBox=true;
				// this.form = {};
			},

			handleStart(item) {
				this.$confirm(this.$t('tip.startAlarm'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					// showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					setAlarm({
						web:item.web,
						account:item.account,
						currency:item.currency,
						param:item.param,
						state:'1',
					}).then(res=>{
						this.getList();
						
						// 启动--成功
						this.$message({
							message: this.$t('tip.startSuc'),
							type: 'success',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					})
				}).catch(() => {
					// 取消
				});
			},
			handleStop(item) {
				this.$confirm(this.$t('tip.stopAlarm'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					// showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					setAlarm({
						web:item.web,
						account:item.account,
						currency:item.currency,
						param:item.param,
						state:'0',
					}).then(res=>{
						this.getList();
						
						// 停止--成功--失败
						this.$message({
							message: this.$t('tip.stopSuc'),
							type: 'success',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					})
				}).catch(() => {
					// 取消
				});
			},
			handleDelete(item) {
				this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					// showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					delAlarm({
						alert_id:item.id,
						account:this.account,
					}).then(res=>{
						this.getList();

						// 删除--成功
						this.$message({
							message: this.$t('tip.delSuc'),
							type: 'success',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					})
				}).catch(() => {
					// 取消
				});
			},
			
			// 交易所
			closeExchange() {
				this.popExchange = false;
			},
			handleSelect(data) {
				this.form.exchange = data.text;
				this.web = data.web;
				this.popExchange = false;
			},
		}
	})
</script>
